import React, { useState, useEffect } from "react"

import VillagerCard from "@/components/VillagerCard"

const getPaginationVillagers = (villagers, pageNum, pageSize = 40) => {
  return villagers.filter((villager, index) => {
    return index >= pageNum * pageSize && index < (pageNum + 1) * pageSize
  })
}

const CardScrollToLoad = ({ villagers }) => {
  const [loadMore, setLoadMore] = useState(false)
  const [villagerStore, setCardStore] = useState([])
  const [pageNum, setPageNum] = useState(1)

  const isElementAtBottom = (scrollThreshold = 370) => {
    const scrollY = window.scrollY
    const visibleHeight = document.documentElement.clientHeight
    const pageHeight = document.documentElement.scrollHeight
    return visibleHeight + scrollY + scrollThreshold >= pageHeight
  }

  useEffect(() => {
    const initVillagers = getPaginationVillagers(villagers,0,40)
    setPageNum(1)
    setCardStore([...initVillagers])
  },[villagers])

  useEffect(() => {
    getData(loadMore)
    setLoadMore(false)
  }, [loadMore])

  useEffect(() => {
    const onPageScroll = () => {
      isElementAtBottom() && setLoadMore(true)
    }
    window.addEventListener("scroll", onPageScroll)
    return () => {
      window.removeEventListener("scroll", onPageScroll)
    }
  }, [])

  const getData = load => {
    if (load) {
      setPageNum(pageNum + 1)
      const loadedVillagers = getPaginationVillagers(villagers, pageNum)
      loadedVillagers.length &&
        setCardStore([...villagerStore, ...loadedVillagers])
    }
  }

  return (
    <>
      {villagerStore.map((villager) => (
        <VillagerCard villager={villager} key={villager.name.en} />
      ))}
    </>
  )
}

export default CardScrollToLoad