import React, { useState, useContext } from "react"
import styled from "styled-components"

import useFormatLocale from "@/hooks/useFormatLocale"
import Image from "@/components/Image"
import villagerBg from "../../icons/villager.svg"
import { IsabelleContext } from "@/contexts/Isabelle"

const styledCard = {
  // card main color
  bg: "#9cd3e5",
  // card border color
  border: "#e3f8ff",
  // tick highlight color
  icon: "#345158",
  // tick border color
  line: "#9cd3e5",
  // checkbox border color
  fill: "#81c9da",
}

const VillagerCard = ({ villager }) => {
  const lng = useFormatLocale()
  const { name, icon, isFriend, isLiked } = villager
  const { dispatch } = useContext(IsabelleContext)
  const handleCheckbox = () => {
    if (isFriend) {
      dispatch({ type: "REMOVE_MARK_FRIEND", id: villager.id })
    } else {
      dispatch({ type: "ADD_MARK_FRIEND", id: villager.id })
    }
  }

  const handleFavors = () => {
    if (isLiked) {
      dispatch({ type: "REMOVE_MARK_FAVOR", id: villager.id })
    } else {
      dispatch({ type: "ADD_MARK_FAVOR", id: villager.id })
    }
  }

  return (
    <StyledVillagerCard bg={villagerBg}>
      <div
        style={{
          borderRadius: `2px`,
          boxShadow: `0 0 0 6px #e3f9ff`,
          backgroundColor: `#e3f9ff`,
        }}
      >
        <div className="villager-bg">
          <div className="villager-icon">
            <Image src={icon.childImageSharp.fluid} />
          </div>
          <div className="villager-favorite" onClick={handleFavors}>
            <FavoriteMarkIcon isFavorited={isLiked} color={styledCard} />
          </div>
          {isFriend && (
            <div className="villager-friend">
              <FriendMarkIcon color={styledCard} />
            </div>
          )}
          <div
            onClick={handleCheckbox}
            style={{
              boxShadow: `0 0 0 3px ${styledCard.fill}`,
            }}
            className="villager-checkbox"
          >
            <CheckMarkIcon isMark={isFriend} color={styledCard} />
          </div>
        </div>
      </div>
      <span className="villager-title">{name[lng]}</span>
    </StyledVillagerCard>
  )
}

export default VillagerCard

const StyledVillagerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  @media (max-width: 450px) {
    width: calc(50vw - 20px);
  }
  .villager-bg {
    width: 124px;
    height: 184px;
    background-size: cover;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-image: url(${(props) => props.bg});
    .villager-icon {
      cursor: pointer;
      width: 72%;
    }
  }
  .villager-title {
    margin-top: 18px;
    max-width: 124px;
    text-align: center;
  }
  .villager-favorite {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
      path {
        transition: 0.3s fill;
      }
    }
  }
  .villager-friend {
    position: absolute;
    bottom: 0px;
    left: 3px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .villager-checkbox {
    position: absolute;
    right: 6px;
    bottom: 6px;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    cursor: pointer;
    background-color: transparent;
    svg {
      position: absolute;
      left: -4px;
      top: -2px;
      width: 26px;
      path {
        transition: 0.3s fill;
      }
    }
  }
`

const FriendMarkIcon = ({ color }) => {
  return (
    <svg viewBox="0 0 60 40">
      <g transform="translate(-1.000000, -1.000000)">
        <g transform="translate(30.929386, 20.970241) scale(-1, 1) rotate(-180.000000) translate(-30.929386, -20.970241) translate(0.929386, 0.970241)">
          <path
            fill={color.icon}
            d="M17,38.9 C14.2,37.8 10,34.1 10,32.8 C10,32.4 11.5,32 13.4,32 C15.7,32 17.3,32.7 18.4,34.3 L20.1,36.5 L19.7,34.3 C19.1,31.6 22,30.2 23.6,32.4 C25.2,34.6 25.9,34.4 25.3,32 C24.9,30.5 25.3,30 26.9,30 C29.4,30 29.5,29.1 27.6,24.5 C26.4,21.8 25.5,21 23.5,21 C16.6,21 9.5,14.8 8.4,7.9 C7.7,3.6 7.3,3.4 4.4,5.4 C3.1,6.3 1.9,6.5 0.9,6 C-1.3,4.5 1.1,1.7 5.4,0.6 C8.7,-0.3 9.47924785,0.395582934 13.7792479,2.79558293 C14.7350281,3.61660544 15.5150301,4.1935865 16.1192538,4.5265261 C16.603919,4.7935865 17.5641677,4.98474446 19,5.1 C19.7576942,4.97483863 20.3576942,4.78368066 20.8,4.5265261 C21.3132497,4.22812511 22.146583,3.58594974 23.3,2.6 C25.7,1.1 28.7,0 30,0 C31.3,0 34.1,1.29558293 36.5,2.79558293 C37.3209684,3.6537561 37.9858311,4.23073715 38.4945881,4.5265261 C38.9413867,4.78629271 39.776524,4.97745068 41,5.1 C42.0981099,4.99423575 42.8981099,4.80307778 43.4,4.5265261 C43.9869988,4.20307778 44.8203321,3.49423575 45.9,2.4 C50.2,0 51.3,-0.3 54.6,0.6 C58.9,1.7 61.3,4.5 59.1,5.9 C58.2,6.5 56.8,6.3 55.3,5.3 C52.2,3.3 50,4.3 50,7.7 C50,13.7 44.2,19.4 36.5,21 C33.9,21.5 33.5,22.1 33.2,25.4 C32.9,28.5 33.2,29.2 34.9,29.7 C36.3,30.1 37,31.1 37.1,32.9 C37.1,35.4 37.1,35.4 38,33.3 C39.2,30.4 42.5,30.1 41.7,33 C41.1,35.3 42.5,35.7 43.4,33.5 C43.9,32 50,31.3 50,32.8 C49.9,34 47.7,36.5 45,38.3 C41.2,40.9 37.3,40.4 33.8,36.9 C31,34.2 30.6,34 29.7,35.5 C28.8,37.2 23.1,40 20.8,39.9 C20.1,39.9 18.4,39.4 17,38.9 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const FavoriteMarkIcon = ({ isFavorited, color }) => {
  const mainColor = isFavorited ? "#f9c50a" : "none"
  const lineColor = isFavorited ? "#b48810" : color.fill
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 54 52">
      <g transform="translate(0.000000, -1.000000)">
        <g transform="translate(27.454545, 26.800000) scale(-1, 1) rotate(-180.000000) translate(-27.454545, -26.800000) translate(0.954545, 0.800000)">
          <path
            fill={lineColor}
            d="M21.5,50 C20.2,49 18.4,46.1 17.5,43.7 C15.8,39.4 15.7,39.3 9.9,38.6 C2.6,37.6 0,35.4 0,29.7 C0,26.1 0.6,25.1 4.5,21.8 L8.9,18 L7.7,12.9 C6.3,6.9 7.2,3.7 11,1.2 C14,-0.8 18.3,-0.2 22.5,2.8 C26,5.3 27,5.3 30.5,2.8 C34.7,-0.2 39,-0.8 42,1.2 C45.8,3.7 46.7,6.9 45.3,12.9 L44.1,18 L48.5,21.8 C52.4,25.1 53,26.1 53,29.7 C53,35.4 50.4,37.6 43.1,38.6 C37.3,39.3 37.2,39.4 35.5,43.7 C33.5,49 30.3,51.8 26.5,51.8 C25,51.8 22.7,51 21.5,50 Z M27.8,46.2 C28.6,45.9 30.3,43 31.5,39.7 C33.7,33.9 33.8,33.8 37.5,33.8 C49,33.8 50.7,31.2 43.6,24.7 L38.5,20.1 L39.9,14 C41.1,8.9 41.1,7.6 39.9,6.1 C38.5,4.1 39.2,3.9 29.5,9.6 C26.6,11.2 26.4,11.2 23.5,9.6 C13.8,3.9 14.5,4.1 13.1,6.1 C11.9,7.6 11.9,8.9 13.1,14 L14.5,20.1 L9.4,24.7 C2.3,31.2 4,33.8 15.5,33.8 C19.2,33.8 19.3,33.9 21.5,39.8 C22.7,43 24.2,45.9 24.9,46.2 C25.5,46.4 26.1,46.7 26.2,46.7 C26.3,46.8 27,46.5 27.8,46.2 Z"
          ></path>
          <path
            fill={mainColor}
            d="M27.8,46.2 C28.6,45.9 30.3,43 31.5,39.7 C33.7,33.9 33.8,33.8 37.5,33.8 C49,33.8 50.7,31.2 43.6,24.7 L38.5,20.1 L39.9,14 C41.1,8.9 41.1,7.6 39.9,6.1 C38.5,4.1 39.2,3.9 29.5,9.6 C26.6,11.2 26.4,11.2 23.5,9.6 C13.8,3.9 14.5,4.1 13.1,6.1 C11.9,7.6 11.9,8.9 13.1,14 L14.5,20.1 L9.4,24.7 C2.3,31.2 4,33.8 15.5,33.8 C19.2,33.8 19.3,33.9 21.5,39.8 C22.7,43 24.2,45.9 24.9,46.2 C25.5,46.4 26.1,46.7 26.2,46.7 C26.3,46.8 27,46.5 27.8,46.2 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const CheckMarkIcon = ({ isMark, color }) => {
  const mainColor = isMark ? color.icon : color.fill
  const lineColor = isMark ? color.fill : color.bg
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 59 47">
      <g transform="translate(0.000000, -1.000000)">
        <g transform="translate(29.500000, 24.180919) scale(-1, 1) rotate(-180.000000) translate(-29.500000, -24.180919) translate(0.000000, 0.680919)">
          <path
            fill={lineColor}
            d="M47,46.3 C46.2,46 40.4,41.4 34.2,36.2 L22.9,26.7 L19.7,29.8 C16.1,33.3 10.8,34.1 7.7,31.8 C4.5,29.3 0,22.7 0,20.4 C0,15.8 16.9,0 21.8,0 C24.7,0 30.1,5.2 48.8,25.8 C58.7,36.7 59.8,39.6 56.3,43.9 C54.4,46.1 49.6,47.4 47,46.3 Z"
          ></path>
          <path
            fill={mainColor}
            d="M52,40.5 C52.6,39.7 52.8,38.5 52.4,37.8 C50.7,35 22.7,5 21.8,5 C20.3,5 5,19 5,20.3 C5,22.1 11.2,28 13.1,28 C14.1,28 16.3,26.2 18,24 C19.7,21.8 21.6,20 22.1,20 C22.7,20 28.9,24.9 36.1,31 C43.2,37.1 49.5,42 49.9,42 C50.4,42 51.3,41.3 52,40.5 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}
