import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import CardScrollToLoad from "@/components/CardScrollToLoad"
import { VillagerTabs, TextTab } from "@/components/Tabs"
import NoMatch from "@/components/NoMatch"

import useTranslation from "@/hooks/useTranslation"
import useLocale from "@/hooks/useLocale"
import IsabelleContextProvider, { IsabelleContext } from "@/contexts/Isabelle"
import createNodeId from "@/utils/createNodeId"
import {
  VILLAGER_SPECIES_TYPES,
  VILLAGER_PERSONALITY_TYPES,
  VILLAGER_HOBBY_TYPES,
} from "@/utils/constants"

const IsabelleFloorWallPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allIsabelleYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          villagers {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            species
            gender
            personality
            hobby
          }
        }
      }
    }
  `)
  const t = useTranslation()
  const lng = useLocale()

  const { villagers, ...IsabelleInfo } = queryData.allIsabelleYaml.nodes[0]

  const villagersHasMarked = villagers.map((villager) => ({
    ...villager,
    isFriend: false,
    isLiked: false,
    id: createNodeId(villager.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const [value3, setValue3] = useState(0)

  const [value4, setValue4] = useState(0)

  const [value5, setValue5] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  const handleChange3 = (event, newValue) => {
    setValue3(newValue)
  }

  const handleChange4 = (event, newValue) => {
    setValue4(newValue)
  }

  const handleChange5 = (event, newValue) => {
    setValue5(newValue)
  }

  return (
    <Layout>
      <PageContainer>
        <div className="header">
          <Image
            className="img"
            src={IsabelleInfo.avatar.childImageSharp.fluid}
          />
          <span>{t(IsabelleInfo.name)}</span>
        </div>
        <VillagerTabs lng={lng} value={value} onChange={handleChange}>
          <TextTab label="all" />
          <TextTab label="movedIn" />
          <TextTab label="moveIn" />
          <TextTab label="favor" />
        </VillagerTabs>
        <VillagerTabs lng={lng} value={value2} onChange={handleChange2}>
          <TextTab label="all" />
          {Object.values(VILLAGER_SPECIES_TYPES).map((species) => (
            <TextTab text={species[lng]} key={species.en} />
          ))}
        </VillagerTabs>
        <VillagerTabs lng={lng} value={value3} onChange={handleChange3}>
          <TextTab label="all" />
          {Object.values(VILLAGER_PERSONALITY_TYPES).map((personality) => (
            <TextTab text={personality[lng]} key={personality.en} />
          ))}
        </VillagerTabs>
        <VillagerTabs lng={lng} value={value4} onChange={handleChange4}>
          <TextTab label="all" />
          {Object.values(VILLAGER_HOBBY_TYPES).map((hobby) => (
            <TextTab text={hobby[lng]} key={hobby.en} />
          ))}
        </VillagerTabs>
        <VillagerTabs lng={lng} value={value5} onChange={handleChange5}>
          <TextTab label="all" />
          <TextTab label="boy" />
          <TextTab label="girl" />
        </VillagerTabs>
        <IsabelleContextProvider>
          <VillagerCards
            villagers={villagersHasMarked}
            tabs={{ value, value2, value3, value4, value5 }}
          />
        </IsabelleContextProvider>
      </PageContainer>
    </Layout>
  )
}

export default IsabelleFloorWallPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img {
      width: 150px;
      border-radius: 50%;
    }
    span {
      margin: 14px 0 18px;
    }
  }
`

const VillagerCards = ({ villagers, tabs }) => {
  const { isabelle } = useContext(IsabelleContext)
  const currentVillagers = useMemo(() => {
    const { value, value2, value3, value4, value5 } = tabs
    const { friend, favor } = isabelle

    const villagersHasMarked = villagers.map((villager) => {
      const villagerIsFriend = friend.filter((id) => id === villager.id)
      const villagerHasLiked = favor.filter((id) => id === villager.id)
      return {
        ...villager,
        isFriend: villagerIsFriend.length ? true : false,
        isLiked: villagerHasLiked.length ? true : false,
      }
    })

    let villagersArr = [...villagersHasMarked]

    if (value === 1) {
      villagersArr = villagersArr.filter((villager) => villager.isFriend)
    } else if (value === 2) {
      villagersArr = villagersArr.filter((villager) => !villager.isFriend)
    } else if (value === 3) {
      villagersArr = villagersArr.filter((villager) => villager.isLiked)
    }

    if (value2) {
      villagersArr = villagersArr.filter(
        (villager) => villager.species === value2
      )
    }

    if (value3) {
      villagersArr = villagersArr.filter(
        (villager) => villager.personality === value3
      )
    }

    if (value4) {
      villagersArr = villagersArr.filter(
        (villager) => villager.hobby === value4
      )
    }

    if (value5 === 1) {
      villagersArr = villagersArr.filter((villager) => villager.gender === 2)
    } else if (value5 === 2) {
      villagersArr = villagersArr.filter((villager) => villager.gender === 1)
    }

    return villagersArr
  }, [isabelle, villagers, tabs])
  return (
    <StyledVillagerCards>
      {currentVillagers.length ? (
        <div className="villager_cards">
          <CardScrollToLoad villagers={currentVillagers} />
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledVillagerCards>
  )
}

const StyledVillagerCards = styled.div`
  .villager_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`
