export const IsabelleReducer = (state, action) => {
  switch (action.type) {
    case "ADD_MARK_FRIEND":
      if (action.id) {
        const collectArr = [action.id, ...state.friend]
        return {
          ...state,
          friend: [...new Set(collectArr)],
        }
      }
    case "REMOVE_MARK_FRIEND":
      const collectArr = state.friend.filter((id) => id !== action.id)
      return {
        ...state,
        friend: [...new Set(collectArr)],
      }
    case "ADD_MARK_FAVOR":
      if (action.id) {
        const favorArr = [action.id, ...state.favor]
        return {
          ...state,
          favor: [...new Set(favorArr)],
        }
      }
    case "REMOVE_MARK_FAVOR":
      const favorArr = state.favor.filter((id) => id !== action.id)
      return {
        ...state,
        favor: [...new Set(favorArr)],
      }
    case "UPDATE_LOCAL_DATA":
      return {
        ...action.value
      }
    default:
      return state
  }
}
