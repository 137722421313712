import React, { createContext,useState, useReducer, useEffect } from 'react';
import { IsabelleReducer } from './reducer';

export const IsabelleContext = createContext();

const IsabelleContextProvider = ({children}) => {
  const initState = {
    friend: [],
    favor: []
  }
  const [hasInit, setHasInit] = useState(false);
  const [isabelle, dispatch] = useReducer(IsabelleReducer, initState);
  useEffect(()=>{
    const localData = localStorage.getItem('isabelle');
    const localIsabelle = localData ? JSON.parse(localData) : initState;
    const { friend=[],favor=[]} = localIsabelle;
    if(Array.isArray(friend) && Array.isArray(favor)){
      if(friend.length || favor.length){
        dispatch({
          type: 'UPDATE_LOCAL_DATA',
          value: localIsabelle
        })
      }
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('isabelle', JSON.stringify(isabelle));
    }
  }, [isabelle]);
  return (
    <IsabelleContext.Provider value={{ isabelle, dispatch }}>
      {children}
    </IsabelleContext.Provider>
  );
}
 
export default IsabelleContextProvider;